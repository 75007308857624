exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-8-bit-gpu-js": () => import("./../../../src/pages/blog/8-bit-gpu.js" /* webpackChunkName: "component---src-pages-blog-8-bit-gpu-js" */),
  "component---src-pages-blog-altmel-gpu-js": () => import("./../../../src/pages/blog/altmel-gpu.js" /* webpackChunkName: "component---src-pages-blog-altmel-gpu-js" */),
  "component---src-pages-blog-basketball-solved-sport-js": () => import("./../../../src/pages/blog/basketball-solved-sport.js" /* webpackChunkName: "component---src-pages-blog-basketball-solved-sport-js" */),
  "component---src-pages-blog-calculating-pie-javascript-js": () => import("./../../../src/pages/blog/calculating-pie-javascript.js" /* webpackChunkName: "component---src-pages-blog-calculating-pie-javascript-js" */),
  "component---src-pages-blog-do-we-need-sleep-js": () => import("./../../../src/pages/blog/do-we-need-sleep.js" /* webpackChunkName: "component---src-pages-blog-do-we-need-sleep-js" */),
  "component---src-pages-blog-earthquakes-in-nepal-js": () => import("./../../../src/pages/blog/earthquakes-in-nepal.js" /* webpackChunkName: "component---src-pages-blog-earthquakes-in-nepal-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-preposterous-life-js": () => import("./../../../src/pages/blog/preposterous-life.js" /* webpackChunkName: "component---src-pages-blog-preposterous-life-js" */),
  "component---src-pages-blog-randomness-does-not-exist-js": () => import("./../../../src/pages/blog/randomness-does-not-exist.js" /* webpackChunkName: "component---src-pages-blog-randomness-does-not-exist-js" */),
  "component---src-pages-blog-react-typescript-cheatsheet-js": () => import("./../../../src/pages/blog/react-typescript-cheatsheet.js" /* webpackChunkName: "component---src-pages-blog-react-typescript-cheatsheet-js" */),
  "component---src-pages-blog-react-typescript-js": () => import("./../../../src/pages/blog/react-typescript.js" /* webpackChunkName: "component---src-pages-blog-react-typescript-js" */),
  "component---src-pages-blog-redefine-artificial-intelligence-js": () => import("./../../../src/pages/blog/redefine-artificial-intelligence.js" /* webpackChunkName: "component---src-pages-blog-redefine-artificial-intelligence-js" */),
  "component---src-pages-blog-rtd-think-or-swim-js": () => import("./../../../src/pages/blog/rtd-think-or-swim.js" /* webpackChunkName: "component---src-pages-blog-rtd-think-or-swim-js" */),
  "component---src-pages-blog-sample-js": () => import("./../../../src/pages/blog/Sample.js" /* webpackChunkName: "component---src-pages-blog-sample-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-startup-js": () => import("./../../../src/pages/startup.js" /* webpackChunkName: "component---src-pages-startup-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

